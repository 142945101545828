import i18n from '@/i18n';
import {
  mdiCurrencyIls,
  mdiCurrencyUsd,
  mdiCurrencyEur,
  mdiCurrencyGbp
} from '@mdi/js';

const CurrencyTypes = [
  {
    text: i18n.t('currency.ILS'),
    value: 'ILS',
    icon: mdiCurrencyIls
  },
  {
    text: i18n.t('currency.USD'),
    value: 'USD',
    icon: mdiCurrencyUsd
  },
  {
    text: i18n.t('currency.EUR'),
    value: 'EUR',
    icon: mdiCurrencyEur
  },
  {
    text: i18n.t('currency.GBP'),
    value: 'GBP',
    icon: mdiCurrencyGbp
  }
];

export default Object.freeze(CurrencyTypes);
