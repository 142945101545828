export const formatAmountWithCurrency = (amount, currency = 'ILS') => {
  if (!currency || amount === undefined || amount === null) {
    return '';
  }

  return new Intl.NumberFormat('he-IL', {
    style: 'currency',
    currency
  }).format(amount);
};

export const getFlooredFixed = (v, d) => {
  return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
};
