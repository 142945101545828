<template>
  <v-footer rounded="lg" class="pa-3 mt-4">
    <div class="d-flex justify-space-between align-center full-width">
      <div class="d-flex" style="column-gap: 20px">
        <div class="text-body-1 font-weight-semibold">
          {{
            $t('customer.orders.totalNetValue', [
              formatAmountWithCurrency(netAmount, currency)
            ])
          }}
        </div>
        <v-divider vertical />
        <div class="text-body-1 font-weight-semibold">
          <!-- {{
            `${$t('customer.orders.vatValue', [
              formatAmountWithCurrency(vatAmount, currency)
            ])} (${vatPercent})`
          }} -->
          {{
            `${$t('customer.orders.vatValue', [
              formatAmountWithCurrency(vatAmount, currency)
            ])}`
          }}
        </div>
        <v-divider vertical />
        <div class="text-body-1 font-weight-semibold">
          {{
            $t('customer.orders.totalAmountValue', [
              formatAmountWithCurrency(totalAmount, currency)
            ])
          }}
        </div>
      </div>
      <slot />
    </div>
  </v-footer>
</template>

<script>
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
export default {
  name: 'AmountSummary',
  props: {
    netAmount: {
      type: Number
    },
    vatAmount: {
      type: Number
    },
    vatPercent: {
      type: String
    },
    totalAmount: {
      type: Number
    },
    currency: {
      type: String
    }
  },
  methods: {
    formatAmountWithCurrency
  }
};
</script>

<style lang="scss" scoped></style>
